import staticQueryData from "../../public/static/d/856328897.json";

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from "react";
import PropTypes from "prop-types";
import "./layout.scss";

var Layout = function Layout(_ref) {
  var children = _ref.children;
  var data = staticQueryData.data;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "bg"
  }, children));
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};
export default Layout;