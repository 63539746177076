import React from "react";
import { Link } from "gatsby";
import Typist from "react-typist";
import Layout from "../components/layout";
import Logo from "../components/logo";
import SEO from "../components/seo";

var IndexPage = function IndexPage() {
  return React.createElement(Layout, null, React.createElement(SEO, {
    title: "Blockhaus"
  }), React.createElement(Logo, null), React.createElement(Typist, {
    cursor: {
      show: false
    }
  }, React.createElement(Typist.Delay, {
    ms: 500
  }), "SHAPING SOLID SOFTWARE"), React.createElement("div", {
    className: "contacts"
  }, React.createElement("div", {
    className: "email"
  }, "dev@blockhauscode.com"), React.createElement("div", {
    className: "address"
  }, "Via Celestino V, 11 L'Aquila - Planet Earth")));
};

export default IndexPage;